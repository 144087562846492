import { makeStyles, webLightTheme } from "@fluentui/react-components";

export const useCalculatorPageStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
});

