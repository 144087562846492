import React from "react";
import { useLayoutStyles } from "./index.styles";
import { Menu } from "../../Components/Menu";
import { Outlet } from "react-router-dom";
import { Divider } from "@fluentui/react-components";

export default function Layout() {
  const useStyles = useLayoutStyles();

  return (
    <div className={useStyles.container}>
      <Menu />
      <Divider appearance="subtle" />
      <Outlet />
    </div>
  );
}
