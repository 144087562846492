// https://p-app-receita-federal.estaleiro.serpro.gov.br/servicos-rfb-apprfb-perdcomp/apprfb-perdcomp/
//consulta/ni/45039237000114?dataInicial=2013-06-17&dataFinal=2024-06-13

import { addYears } from "@fluentui/react-calendar-compat";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SearchState } from "Pages/Search/index.types";
import { stripCnpj } from "Utils";
import { AxiosResponse } from "axios";
import {
  HttpClient,
  ServiceProvider,
  useSerproConfig,
} from "config/httpClient";
import moment from "moment";
import { PerDecomp } from "types/OnDemand.types";

const getSearchPerDecomp = ({
  searchParams: { doc },
  perDecomp: { searchInterval },
}: SearchState) => {
  const config = useSerproConfig();
  const today = new Date();
  const [docString, isValid] = stripCnpj(doc);
  const to = moment(today).format("YYYY-MM-DD");
  console.log(searchInterval);
  const from = moment(addYears(today, -searchInterval)).format("YYYY-MM-DD");
  const api = `/perdecomp/doc/${docString}/from/${from}/to/${to}`;
  return HttpClient(ServiceProvider.Nin).get<PerDecomp[]>(api, config);
};

export const useSearchPerDecomp = (
  onSuccess: (
    data: AxiosResponse<PerDecomp[]>,
    variables: SearchState,
    context: unknown,
  ) => void,
) =>
  useMutation({
    mutationFn: getSearchPerDecomp,
    onSuccess,
    retry: 1,
  });
