import { DatasetsStatus } from "types/Log.types";
import * as cnpj from "@fnando/cnpj"; // import just one function
import { CNPJ, FormatCnpj } from "Pages/Search/index.types";

export const formatAsCnpj: FormatCnpj = (cnpjString): [CNPJ, boolean] => {
  if (cnpj.isValid(cnpjString)) {
    return [cnpj.format(cnpjString) as CNPJ, true];
  }
  return [cnpjString, false];
};

export const stripCnpj = (cnpjValue: CNPJ): [string, boolean] => {
  if (cnpj.isValid(cnpjValue)) {
    return [cnpj.strip(cnpjValue), true];
  }
  return [cnpjValue, false];
};

export const toggleSet = <T>(list: Set<T>, elem: T, toAdd: boolean): Set<T> => {
  toAdd ? list.add(elem) : list.delete(elem);
  return list;
};

export const useIsDevEnvironment = () => ({
  isDevelopment: !!window.location.toString().includes("localhost"),
});

export const exportSet = (_key, value) =>
  value instanceof Set ? [...value] : value;

export const extractDocument = (string: string): number => {
  let match = string.match(/\d+/);
  return Number(match[0]) ?? 0;
};

export const extractDatasetStatus = (
  datasetName: string,
  datasetStatus: DatasetsStatus,
) => {
  const status = datasetStatus[datasetName];
  if (status) {
    return status[0];
  } else {
    return Object.keys(datasetStatus)
      .map((key) => datasetStatus[key][0])
      .pop();
  }
};
