import { ResultItem } from "hooks/useLocalStorage";
import { ResultCardItem, ResultTypes } from "./ResultsCard.types";
import { ApiResponseType } from "Utils";
import { match } from "ts-pattern";
import { extractPerDecompData } from "../PerDecomp/fileUtils";
import { extractProjetosPublicosData } from "../ProjetosPublicos/fileUtils";
import { extractProcessosJudiciaisData } from "../ProcessosJudiciais/fileUtils";
import { extractDebitosFederaisData } from "../DebitosFederais/fileUtils";

export const useMapResultCardItem = (item: ResultItem): ResultCardItem => {
  const resultCardItem = createDefaultItem(item);
  return match(resultCardItem)
    .with({ apiType: ApiResponseType.Comprot }, withComprotItem(item))
    .with({ apiType: ApiResponseType.PerDecomp }, withPerDecomp(item))
    .with(
      { apiType: ApiResponseType.DebitosFederais },
      withDebitosFederais(item),
    )
    .with({ apiType: ApiResponseType.LeiDoBem }, withLeiDoBem(item))
    .with(
      { apiType: ApiResponseType.ProcessosJudiciais },
      withProcessosJudiciais(item),
    )
    .with(
      { apiType: ApiResponseType.ProjetosPublicos },
      withProjetosPublicos(item),
    )
    .exhaustive();
};

const createDefaultItem = (item: ResultItem): ResultCardItem => {
  const getKeyByValue = (object: any, value: string) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const apiTypeKey = getKeyByValue(
    ApiResponseType,
    Object.keys(item.Status).pop(),
  );

  return {
    lastMovementDate: undefined, //result?.ComprotProcesses?.LastMovementDate,
    totalNumberOfProcesses: undefined, //result?.ComprotProcesses?.TotalNumberOfProcesses,
    queryId: item.QueryId,
    dataset: {
      label: undefined,
      status: undefined,
    },
    apiType: ApiResponseType[apiTypeKey],
    doc: item.searchParams.doc,
    queryDate: item.QueryDate,
    file: {
      label: item.QueryId,
      link: null,
    },
  };
};

const withProjetosPublicos =
  (originalItem: ResultItem) =>
  (item: ResultCardItem): ResultCardItem => {
    const resultItem = originalItem.Result.pop();
    return {
      ...item,
      doc: originalItem.searchParams.doc,
      file: {
        ...item.file,
        data: extractProjetosPublicosData(resultItem.OnlineCertificates),
      },
    };
  };

const withProcessosJudiciais =
  (originalItem: ResultItem) =>
  (item: ResultCardItem): ResultCardItem => {
    const resultItem = originalItem.Result.pop();
    return {
      ...item,
      file: {
        ...item.file,
        data: extractProcessosJudiciaisData(resultItem.Lawsuits),
      },
    };
  };

const withDebitosFederais =
  (originalItem: ResultItem) =>
  (item: ResultCardItem): ResultCardItem => {
    const resultItem = originalItem.Result.pop();
    return {
      ...item,
      file: {
        ...item.file,
        data: extractDebitosFederaisData(resultItem.GovernmentDebtors),
      },
    };
  };

const withPerDecomp =
  (originalItem: ResultItem) =>
  (item: ResultCardItem): ResultCardItem => {
    const resultItem = originalItem.Result.pop();
    return {
      ...item,
      file: {
        ...item.file,
        data: extractPerDecompData(resultItem.PerDecomp),
      },
    };
  };

const withLeiDoBem =
  (originalItem: ResultItem) =>
  (item: ResultCardItem): ResultCardItem => {
    return item;
  };

const withComprotItem =
  (originalItem: ResultItem) =>
  (item: ResultCardItem): ResultCardItem => {
    const resultItem = originalItem.Result.pop();
    return {
      ...item,
      lastMovementDate: resultItem.ComprotProcesses.LastMovementDate,
      totalNumberOfProcesses:
        resultItem.ComprotProcesses.TotalNumberOfProcesses,
      queryId: originalItem.QueryId,
      dataset: {
        label: ResultTypes[Object.keys(originalItem.Status).pop()].label,
        status: originalItem.Status,
      },
      apiType: item.apiType,
      doc: originalItem.searchParams.doc,
      queryDate: originalItem.QueryDate,
      // status:
      file: {
        label: originalItem.QueryId,
        link: resultItem.ComprotProcesses.RawResultFile,
      },
    };
  };
