import { makeStyles, webLightTheme } from "@fluentui/react-components";

export const useLoginPageStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  formContainer: {
    marginTop: '15px',
    width: '400px'
  },
  formButtonsContainer: {
    marginTop: '15px',
  },
  formButtons: {
    marginRight: '5px',
  },
  title: {
    marginTop: '10px'
  }
});

