import React from "react";
import {
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
} from "@fluentui/react-components";

export default function AppCardMessageBar(props: {
  isError: boolean;
  message?: string;
}) {
  if (!props.isError) return null;
  return (
    <MessageBar intent="warning">
      <MessageBarBody style={{ textWrap: "wrap" }}>
        <MessageBarTitle>
          Um erro ocorreu ao tentar realizar a consulta:
        </MessageBarTitle>
        {props.message}
      </MessageBarBody>
    </MessageBar>
  );
}
