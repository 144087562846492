import { makeStyles, webLightTheme } from "@fluentui/react-components";

export const useLayoutStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
});
