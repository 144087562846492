import React from "react";
import {
  Card,
  Checkbox,
  Field,
  Radio,
  RadioGroup,
} from "@fluentui/react-components";
import { ComprotCardProps } from "./ComprotCard.types";
import { useCardStyles } from "../index.styles";
import { ResultTypes } from "../Results";

export default function ComprotCard({
  options,
  state: { isChecked, searchInterval },
  setState,
  isDisabled,
}: ComprotCardProps) {
  const cardStyles = useCardStyles();
  return (
    <Card>
      <Checkbox
        disabled={isDisabled}
        onChange={(_, data) => {
          if (data.checked == false) {
            setState({ isChecked: data.checked, searchInterval: 0 });
          }
        }}
        checked={isChecked}
        label={<b>{ResultTypes.ondemand_comprot_processes_company.label}</b>}
      />
      <Field label="Periodo">
        <RadioGroup
          disabled={isDisabled}
          onChange={(_, data) =>
            setState({
              searchInterval: Number(data.value),
              isChecked: !!data.value,
            })
          }
          value={searchInterval?.toString()}
          required
          layout="horizontal"
          className={cardStyles.sideBySideRadio}
        >
          {options.map((option) => (
            <Radio
              disabled={isDisabled}
              key={option.Value}
              value={option.Value.toString()}
              label={option.Label}
            />
          ))}
        </RadioGroup>
      </Field>
    </Card>
  );
}
