import React, { useCallback, useMemo } from "react";
import { Link, TableCellLayout } from "@fluentui/react-components";
import { ResultCardItem } from "../ResultsCard.types";
import {
  useCreateExcelSheetAndAppend,
  useCreateNewExcel,
  useDownloadExcel,
} from "hooks/useExcelJs";
import moment from "moment";
import { DocumentDatabaseFilled } from "@fluentui/react-icons";

export type CreateFileRenderProps = {
  item: ResultCardItem;
};

export const CreateFileRender: React.FC<CreateFileRenderProps> = ({
  item: { file, doc, apiType },
}: CreateFileRenderProps) => {
  let link: JSX.Element = null;
  const fileData = useMemo(() => file.data, [file.data]);
  const parseExcel = useCallback(() => {
    const excelBook = useCreateNewExcel();
    useCreateExcelSheetAndAppend({
      book: excelBook,
      data: Object.values(fileData),
      sheetName: `${moment().format(`DD-MM-YYYY`)}`,
    });
    useDownloadExcel(
      excelBook,
      `${apiType}-${moment().format("DDMMYYYY")}.xlsx`,
    );
  }, []);
  link = <Link onClick={() => parseExcel()}>{doc}</Link>;
  return (
    <TableCellLayout media={<DocumentDatabaseFilled />}>
      {link ?? doc}
    </TableCellLayout>
  );
};
