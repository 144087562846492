export const PALZIN_TOKEN = "a952b475-a105-4e87-8ba2-e517b24ed207";

export type YearOption = {
  Label: string;
  Value: number;
};

export const ApiResponseType = {
  Comprot: "ondemand_comprot_processes_company",
  ProcessosJudiciais: "processes",
  DebitosFederais: "government_debtors",
  ProjetosPublicos: "ondemand_public_projects_company",
  LeiDoBem: "",
  PerDecomp: "perdecomp",
} as const;

export type ApiResponseType =
  (typeof ApiResponseType)[keyof typeof ApiResponseType];

export const SearchType = {
  Comprot: "Comprot",
  ProcessosJudiciais: "ProcessosJudiciais",
  DebitosFederais: "DebitosFederais",
  ProjetosPublicos: "ProjetosPublicos",
  PerDecomp: "PerDecomp",
  LeiDoBem: "LeiDoBem",
} as const;
export type SearchType = (typeof SearchType)[keyof typeof SearchType];

export enum OnDemandDatasets {
  Comprot = "ondemand_comprot_processes_company{comprotprocesses.protocoldate,comprotprocesses.subject,comprotprocesses.type,comprotprocesses.currentlocation,comprotprocesses.movementshistory.status,comprotprocesses.movementshistory.movementtype,comprotprocesses.movementshistory.currentagency,lastmovementdate,totalnumberofprocesses,rawresultfiletype,rawresultfile}",
  PublicProjectsCompany = "ondemand_public_projects_company",
  Processes = "processes",
  GovernmentDebtors = "government_debtors{debtorname,totaldebtvalue,totaldebtvalueperorigin,totaldebts,totaldebtsperorigin,debts.debtorigin,debts.consolidatedvalue,debts.registrationnumber,debts.registrationsituationtype,debts.registrationsituation,debts.registrationdate,debts.creationdate,debts.lastupdatedate,debts.source}",
}

export const IntervalOptions: YearOption[] = [
  {
    Label: "2 anos",
    Value: 2,
  },
  {
    Label: "5 anos",
    Value: 5,
  },
  {
    Label: "8 anos",
    Value: 8,
  },
  {
    Label: "10 anos",
    Value: 10,
  },
];

export const ProcJudIntervalOptions = IntervalOptions;
export const ComprotIntervalOptions = IntervalOptions;

export const LOGIN_STORAGE_KEY = btoa(
  "nin.alvespacheco.adv.br+218b3d31-ad36-42c6-9c8c-7154a0cd971a",
);
