import React from "react";
import {
  Card,
  Checkbox,
  Field,
  Radio,
  RadioGroup,
} from "@fluentui/react-components";
import { PerDecompCardProps } from "./PerDecomp.types";
import { useCardStyles } from "../index.styles";
import { ResultTypes } from "../Results";

export default function PerDecomp({
  state: { isChecked, searchInterval },
  setState,
  options,
  isDisabled,
}: PerDecompCardProps) {
  const cardStyles = useCardStyles();

  return (
    <Card>
      <Checkbox
        checked={isChecked}
        onChange={(_, data) =>
          setState({ isChecked: !!data.checked, searchInterval: 0 })
        }
        label={<b>{ResultTypes.perdecomp.label}</b>}
      />
      <Field label="Periodo">
        <RadioGroup
          disabled={isDisabled}
          onChange={(_, data) =>
            setState({
              searchInterval: Number(data.value),
              isChecked: !!data.value,
            })
          }
          value={searchInterval?.toString()}
          required
          layout="horizontal"
          className={cardStyles.sideBySideRadio}
        >
          {options.map((option) => (
            <Radio
              disabled={isDisabled}
              key={option.Value}
              value={option.Value.toString()}
              label={option.Label}
            />
          ))}
        </RadioGroup>
      </Field>
    </Card>
  );
}
