import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const useFetchResultFile = ({ fileUrl }) =>
  useQuery({
    queryKey: ["RawResultFile", { fileUrl }],
    queryFn: fetchResultFile,
  });

const fetchResultFile = async ({ queryKey }) => {
  const [_key, { fileUrl }] = queryKey;

  const proxy = `https://s3-proxy.azure-api.net/get-file?file_url=${fileUrl}`;
  return axios.get(proxy);
};
