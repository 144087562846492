import { OnlineCertificate } from "types/OnDemand.types";

export const extractProjetosPublicosData = (
  resultItem: OnlineCertificate[],
) => {
  const onlineCertificate = resultItem.pop();
  const rows = [];
  const row = { Origin: onlineCertificate.Origin };

  Object.keys(onlineCertificate.AdditionalOutputData).forEach((key) => {
    const _key = key.split(".");
    row[_key[0]] = null;
    rows[_key[1]] = row;
  });
  const _rows = rows.map((row, ix) => {
    const _row = { Origin: row.Origin };
    Object.keys(row).forEach((rowKey) => {
      const value = onlineCertificate.AdditionalOutputData[`${rowKey}.${ix}`];
      _row[rowKey] = value ? value : row[rowKey];
    });
    return _row;
  });
  return { ..._rows };
};
