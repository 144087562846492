import { LOGIN_STORAGE_KEY, PALZIN_TOKEN } from "Utils";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { useUserLogged } from "hooks/Login/useLogin";
import { ApiResponse } from "types/OnDemand.types";
export const ServiceProvider = {
  BigDataCorp: "BigDataCorp",
  Nin: "Nin",
  Palzin: "Palzin",
} as const;

export type ServiceProvider =
  (typeof ServiceProvider)[keyof typeof ServiceProvider];

export const HttpClient = (
  api: ServiceProvider = ServiceProvider.BigDataCorp,
): AxiosInstance => {
  switch (api) {
    case ServiceProvider.Nin:
      return axios.create({
        baseURL: "https://s3-proxy.azure-api.net",
        timeout: 10000 * 120,
      });
    case ServiceProvider.Palzin:
      const palzinClient = axios.create({
        baseURL: "https://api.palzin.live/v1",
      });
      addRequestInterceptorPalzin(palzinClient);
      return palzinClient;
    case ServiceProvider.BigDataCorp:
      const bigDataCorpClient = axios.create({
        baseURL: "https://plataforma.bigdatacorp.com.br",
        timeout: 10000 * 120,
      });
      addRequestInterceptor(bigDataCorpClient);
      addResponseInterceptor(bigDataCorpClient);
      return bigDataCorpClient;
  }
};

const addRequestInterceptorPalzin = (client) => {
  client.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers["Content-Type"] = "application/json";
    config.headers["Authorization"] = `Bearer ${PALZIN_TOKEN}`;
    return { ...config };
  });
};

const addRequestInterceptor = (client) => {
  client.interceptors.request.use((config: AxiosRequestConfig) => {
    const loginInfo = useUserLogged();
    if (loginInfo) {
      const preConfig = useBigDataCorpConfig({
        token: loginInfo.token,
        tokenID: loginInfo.tokenID,
      });
      return { ...config, ...preConfig };
    }
    return { ...config };
  });
};

const addResponseInterceptor = (client) => {
  client.interceptors.response.use(
    (response: AxiosResponse<ApiResponse>) => {
      if (response.data.Status?.login?.pop().Message.includes("INVALID")) {
        localStorage.removeItem(LOGIN_STORAGE_KEY);
        throw new Error("INVALID CREDENTIALS");
      }
      return response;
    },
    (error) => {
      console.log(error);
      throw new Error(error);
    },
  );
};

export const useBigDataCorpConfig = ({
  token,
  tokenID,
}: {
  token: string;
  tokenID: string;
}): AxiosRequestConfig => {
  const config: AxiosRequestConfig = {
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      AccessToken: token,
      TokenId: tokenID,
    },
  };
  return config;
};

export const useSerproConfig = (): AxiosRequestConfig => {
  const config: AxiosRequestConfig = {
    headers: {},
  };
  return config;
};
