import { useMutation } from "@tanstack/react-query";
import { LOGIN_STORAGE_KEY } from "Utils";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { HttpClient } from "config/httpClient";
import { useAmpli } from "hooks/useAmpli";

export type LoginMutationInput = {
  login: string;
  password: string;
  expires?: number;
};

export type LoginSuccessResponse = {
  expiration: string;
  message: string;
  success: string;
  token: string;
  tokenID: string;
  login: string;
};

export const useUserLogged = (): LoginSuccessResponse => {
  const loginInfo = localStorage.getItem(LOGIN_STORAGE_KEY);
  if (loginInfo) {
    return JSON.parse(atob(loginInfo));
  }
  return null;
};

const postLogin = ({
  login,
  password,
  expires = 100,
}: LoginMutationInput): Promise<AxiosResponse<LoginSuccessResponse>> => {
  const config: AxiosRequestConfig = {
    headers: { accept: "application/json", "content-type": "application/json" },
  };

  return HttpClient().post(
    "tokens/gerar",
    { login, password, expires },
    config,
  );
};

export const useLoginMutation = () =>
  useMutation({
    mutationFn: postLogin,
    onSuccess: (data, variables) => {
      const { tracker } = useAmpli();
      tracker.identify(data.data.login);
      const response = btoa(
        JSON.stringify({ login: variables.login, ...data.data }),
      );
      localStorage.setItem(LOGIN_STORAGE_KEY, response);
    },
    retry: 1,
  });
