import { useIsDevEnvironment } from "Utils";
import { Experiment } from "@amplitude/experiment-js-client";
import { ampli } from "../ampli";
import { FeatureFlags } from "Utils/FeatureFlags";

const AMPLI_PROD_TOKEN = "2a9e5058dfb81a70664c1d86e6cd7288";
const AMPLI_LOCAL_TOKEN = "87b3d9754457ceef3126498ff11cf1d4";

export const useAmpli = () => {
  const { isDevelopment } = useIsDevEnvironment();
  ampli.load({
    client: {
      apiKey: isDevelopment ? AMPLI_LOCAL_TOKEN : AMPLI_PROD_TOKEN,
    },
  });

  const experiment = Experiment.initializeWithAmplitudeAnalytics(
    isDevelopment ? AMPLI_LOCAL_TOKEN : AMPLI_PROD_TOKEN,
    { fetchOnStart: false, initialVariants: FeatureFlags },
  );
  experiment.start();

  return { tracker: ampli, featureFlag: experiment };
};
