import { Avatar, Link, Subtitle1 } from "@fluentui/react-components";
import { useMenuStyles } from "Pages/Search/index.styles";
import { AppRoute, appRoutes } from "config/routes";
import React, { useCallback, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { AvatarPopover } from "./Popovers/Avatar";
import { useUserLogged } from "hooks/Login/useLogin";
import { LOGIN_STORAGE_KEY } from "Utils";
import { removeStorageValue } from "hooks/useLocalStorage";
import { useAmpli } from "hooks/useAmpli";
import { FeatureFlag } from "Utils/FeatureFlags";

const searchIcon = require("../svg/search_icon.svg").default;

export type MenuProps = {};

export function Menu(props: MenuProps = null) {
  // (1) Initialize the experiment client with Amplitude Analytics.
  const menuStyles = useMenuStyles();
  const loginInfo = useUserLogged();
  const navigate = useNavigate();
  const { featureFlag } = useAmpli();

  if (loginInfo == null) {
    navigate("/login");
    return null;
  }

  const logoutHandler = useCallback(() => {
    removeStorageValue(LOGIN_STORAGE_KEY);
    navigate("/login");
  }, [loginInfo]);

  const renderMenuItem = (item: AppRoute) => (
    <Link
      key={`link-${item.label}`}
      appearance="subtle"
      className={menuStyles.buttons}
    >
      <RouterLink
        style={{ textDecoration: "none", color: "black" }}
        key={item.label}
        to={item.path}
      >
        {item.label}
      </RouterLink>
    </Link>
  );

  const linksFF = featureFlag.variant(FeatureFlag.NewLinks);
  const items = appRoutes
    .filter((route) => Array.from(linksFF.payload).includes(route.id))
    .filter((route) => route.label && route.icon)
    .map((item) => renderMenuItem(item));

  return (
    <>
      <div className={menuStyles.container}>
        <div className={menuStyles.container}>
          <img className={menuStyles.logo} width={150} src="nin-logo.png" />
          <div className={menuStyles.menuContainer}>{items}</div>
        </div>
        <AvatarPopover loginInfo={loginInfo} logoutHandler={logoutHandler}>
          <Avatar
            style={{ alignSelf: "revert" }}
            name={loginInfo.login}
            aria-label={loginInfo.login}
          />
        </AvatarPopover>
      </div>
    </>
  );
}
