import { ApiResponseType } from "Utils";
import { CNPJ } from "../index.types";
import { Status } from "types/OnDemand.types";

export const ResultTypes = {
  ondemand_comprot_processes_company: {
    label: "Comprot",
    type: ApiResponseType.Comprot,
  },
  government_debtors: {
    label: "Debitos Federais",
    type: ApiResponseType.DebitosFederais,
  },
  ondemand_public_projects_company: {
    label: "Financiamentos / Projetos Publicos",
    type: ApiResponseType.ProjetosPublicos,
  },
  perdecomp: {
    label: "Per/Decomp",
    type: ApiResponseType.PerDecomp,
  },
  processes: {
    label: "Processos Judiciais",
    type: ApiResponseType.ProcessosJudiciais,
  },
} as const;

export type ResultsCardProps = {};

export type ResultCardItem = {
  lastMovementDate: string;
  totalNumberOfProcesses: number;
  doc: CNPJ;
  apiType: ApiResponseType;
  queryId: string;
  dataset: { label: string; status: Status };
  queryDate: string;
  file: {
    label: string;
    link: string;
    data?: any;
  };
};
