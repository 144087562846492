import { SearchParams } from "Pages/Search/index.types";
import { exportSet } from "Utils";
import { ApiResponse } from "types/OnDemand.types";

export type ResultItem = ApiResponse & { searchParams: SearchParams };
export type ResultItems = Set<ResultItem>;

export const SEARCH_STORAGE_KEY = "2411bf39-6749-4f89-a712-09d6c1411897";

export const getStorageValue = (key: string): ResultItems => {
  try {
    const saved = localStorage.getItem(key);
    if (!saved) {
      return new Set<ResultItem>();
    }
    return new Set<ResultItem>(JSON.parse(saved, exportSet));
  } catch (e) {
    throw new Error("Problem when retrieving the local storage");
  }
};

export const addToStorageValue = (key: string, value: ResultItem): void => {
  const previous = getStorageValue(key);
  previous.add(value);
  localStorage.setItem(key, JSON.stringify(previous, exportSet));
};

export const setStorageValue = (key: string, value: ResultItems): void => {
  localStorage.setItem(key, JSON.stringify(value, exportSet));
};

export const removeStorageValue = (key: string): void => {
  localStorage.removeItem(key);
};
