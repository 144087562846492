import { addYears } from "@fluentui/react-calendar-compat";
import { useMutation } from "@tanstack/react-query";
import { SearchParams, SearchState } from "Pages/Search/index.types";
import { OnDemandDatasets } from "Utils";
import { AxiosResponse } from "axios";
import {
  HttpClient,
  useBigDataCorpConfig as useGetConfig,
} from "config/httpClient";
import { ResultItem } from "hooks/useLocalStorage";
import { useUserLogged } from "hooks/Login/useLogin";
import moment from "moment";
import { ApiResponse } from "types/OnDemand.types";

export const getSearchComprot = async ({
  searchParams: { doc },
  comprot: { searchInterval },
}: SearchState) => {
  const today = new Date();
  const to = moment(today).format("DD/MM/YYYY");
  const from = moment(addYears(today, -searchInterval)).format("DD/MM/YYYY");
  const api = "ondemand";
  let payload: any = {
    Datasets: OnDemandDatasets.Comprot,
    q: `doc\{${doc}\},startdate\{${from}\},enddate\{${to}\},dateformat{dd/MM/yyyy},returnUpdates{false}`,
  };
  return HttpClient().post<ApiResponse>(api, payload);
};

export const defaultComprotResults = (
  searchParams: SearchParams,
): ResultItem => ({
  searchParams,
  ElapsedMilliseconds: null,
  QueryId: null,
  Result: null,
  Status: null,
  Evidences: null,
  QueryDate: null,
});

export const useSearchComprot = (
  onSuccess: (
    data: AxiosResponse<ApiResponse>,
    variables: SearchState,
    context: unknown,
  ) => void,
) =>
  useMutation({
    mutationFn: getSearchComprot,
    onSuccess,
    retry: 1,
  });
