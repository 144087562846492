import { useMutation } from "@tanstack/react-query";
import { SearchState } from "Pages/Search/index.types";
import { OnDemandDatasets } from "Utils";
import { AxiosResponse } from "axios";
import { HttpClient } from "config/httpClient";
import { ApiResponse } from "types/OnDemand.types";

export const getProjetosPublicosResults = ({ searchParams }: SearchState) => {
  const response = HttpClient().post<ApiResponse>("ondemand", {
    Datasets: OnDemandDatasets.PublicProjectsCompany,
    q: `doc\{${searchParams.doc}\}`,
  });

  return response;
};

export const useSearchProjetosPublicos = (
  onSuccess: (
    data: AxiosResponse<ApiResponse>,
    variables: SearchState,
    context: unknown,
  ) => void,
) =>
  useMutation({
    mutationFn: getProjetosPublicosResults,
    onSuccess,
    retry: 1,
  });
