import * as XLSX from "xlsx";

export type UseDownloadExcel = {
  book: XLSX.WorkBook;
  sheetName: string;
  data: any;
};

export const useCreateExcelSheetAndAppend = ({
  sheetName,
  data,
  book,
}: UseDownloadExcel) => {
  const sheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(book, sheet, sheetName);
};

export const useCreateNewExcel = () => XLSX.utils.book_new();

export const useDownloadExcel = (book: XLSX.WorkBook, filename: string) =>
  XLSX.writeFile(book, filename);
