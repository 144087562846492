import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRoutes } from "config/routes";
import Layout from "Pages/Layout";
import LoginPage from "Pages/Login";
import { useUserLogged } from "hooks/Login/useLogin";
import { useAmpli } from "hooks/useAmpli";

export default function App() {
  const routes = useRoutes();
  const user = useUserLogged();
  const navigate = useNavigate();
  const location = useLocation();
  const { tracker } = useAmpli();

  useEffect(() => {
    tracker.pageView({
      location: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else tracker.identify(user.login);
  }, [user]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {routes}
      </Route>
      <Route path="/login" element={<LoginPage />}></Route>
    </Routes>
  );
}
