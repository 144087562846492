import { makeStyles, tokens } from "@fluentui/react-components";

export const useMenuStyles = makeStyles({
  container: {
    width: "98%",
    marginBottom: tokens.spacingVerticalSNudge,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  logo: {},
  menuContainer: {
    marginTop: tokens.spacingHorizontalSNudge,
  },
  icon: {
    width: "22px",
    height: "22px",
    alignItems: "center",
    marginRight: tokens.spacingHorizontalSNudge,
  },
  buttons: {
    marginLeft: tokens.spacingHorizontalXXL,
    marginRight: tokens.spacingHorizontalMNudge,
    fontWeight: tokens.fontWeightRegular,
    display: "inline-flex",
  },
});

export const useCardStyles = makeStyles({
  title3: {
    marginBottom: tokens.spacingHorizontalMNudge,
  },
  mainCardInputsContainer: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  mainCardInputs: {
    width: "150px",

    marginRight: tokens.spacingHorizontalMNudge,
  },
  comprotTextInputs: {
    width: "180px",
    marginLeft: tokens.spacingHorizontalSNudge,
    marginRight: tokens.spacingHorizontalMNudge,
  },
  sideBySideCb: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  sideBySideRadio: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  card: {
    marginTop: tokens.spacingVerticalXL,
    padding: tokens.spacingVerticalXL,
    width: "35%",
    marginRight: tokens.spacingHorizontalMNudge,
  },
});

export const useMainPageStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
});
