import React, { useCallback, useMemo } from "react";
import { Link, TableCellLayout } from "@fluentui/react-components";
import { ResultCardItem } from "../ResultsCard.types";
import { useFetchResultFile } from "hooks/useOnDemand";
import {
  useCreateExcelSheetAndAppend,
  useCreateNewExcel,
  useDownloadExcel,
} from "hooks/useExcelJs";
import moment from "moment";
import { DocumentDatabaseFilled } from "@fluentui/react-icons";

export type RawFileRenderProps = {
  item: ResultCardItem;
};

export const RawFileRender: React.FC<RawFileRenderProps> = ({
  item,
}: RawFileRenderProps) => {
  let link: JSX.Element = null;
  const { file, doc } = item;
  if (file.link) {
    const { data, isSuccess } = useFetchResultFile({ fileUrl: file.link });
    const parseExcel = useCallback(() => {
      const cleanedData = String(data?.data).split(
        "#_BDC_EVIDENCE_SEPARATOR_#",
      );

      const excelBook = useCreateNewExcel();
      const processos = [];
      cleanedData.map((row, i) => {
        const data = JSON.parse(row);
        if (data?.processos) {
          return useCreateExcelSheetAndAppend({
            book: excelBook,
            data: data?.processos,
            sheetName: `Relatorio ${moment().format(`DD-MM-YYYY-${i}`)}`,
          });
        }
        return processos.push(data?.processo);
      });
      useCreateExcelSheetAndAppend({
        book: excelBook,
        data: processos,
        sheetName: `Relatorio 2 ${moment().format(`DD-MM-YYYY`)}`,
      });
      useDownloadExcel(
        excelBook,
        `${item.apiType}-${moment().format("DDMMYYYY")}.xlsx`,
      );
    }, [data, isSuccess]);

    link = <Link onClick={isSuccess ? () => parseExcel() : null}>{doc}</Link>;
  }
  return (
    <TableCellLayout media={<DocumentDatabaseFilled />}>
      {link ?? doc}
    </TableCellLayout>
  );
};
