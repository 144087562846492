import React from "react";
import { Card, Checkbox } from "@fluentui/react-components";
import { LeiDoBemCardProps } from "./LeiDoBemCard.types";

export default function LeiDoBemCard({
  state: { isChecked },
  setState,
}: LeiDoBemCardProps) {
  return (
    <Card>
      <Checkbox
        checked={isChecked}
        onChange={(_, data) => setState({ isChecked: !!data.checked })}
        label={<b>Lei do Bem</b>}
      />
    </Card>
  );
}
