import React from "react";
import { Display } from "@fluentui/react-components";
import { useNotFoundStyles } from "./index.styles";

export default function NotFoundPage() {
  const useStyles = useNotFoundStyles();

  return (
    <div className={useStyles.container}>
      <Display>404 - Pagina Nao Encontrada</Display>
    </div>
  );
}
