import { useState } from "react";
import { SearchState } from "../index.types";
import { SearchType } from "Utils";
import { ResultItem } from "hooks/useLocalStorage";

export const useSearchState = (): [
  SearchState,
  React.Dispatch<React.SetStateAction<SearchState>>,
] => {
  const [searchState, setSearchState] = useState<SearchState>({
    searchType: new Set<SearchType>(),
    searchResult: new Set<ResultItem>(),
    searchParams: {
      doc: "",
      isCnpjValid: false,
    },
    projetosPublicos: {
      isChecked: false,
    },
    comprot: {
      isChecked: false,
      searchInterval: 0,
    },
    processosJudiciais: {
      civel: false,
      trabalhista: false,
      tributario: false,
      searchInterval: 0,
    },
    perDecomp: {
      isChecked: false,
      searchInterval: 0,
    },
    debitosFederais: {
      isChecked: false,
    },
    leiDoBem: {
      isChecked: false,
    },
  });
  return [searchState, setSearchState];
};
