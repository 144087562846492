import { FieldApi } from "@tanstack/react-form";

export type StringInputValidator = {
  length: number;
  errors: string[];
};
export const stringInputValidator =
  ({ length, errors }: StringInputValidator) =>
  (value) => {
    !value ? errors[0] : value.length < length ? errors[1] : undefined;
  };

export const isFormFieldValid = ({
  field,
}: {
  field: FieldApi<any, any, any, any>;
}) => (field.state.meta.touchedErrors.length == 0 ? "none" : "error");

export const fieldErrors = ({
  field,
}: {
  field: FieldApi<any, any, any, any>;
}) =>
  field.state.meta.touchedErrors
    ? field.state.meta.touchedErrors.join(" - ")
    : null;
