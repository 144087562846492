import React from "react";
import { Card, Checkbox } from "@fluentui/react-components";
import { useCardStyles } from "../index.styles";
import { ProjPubCardProps } from "./ProjetosPublicosCard.types";
import { ResultTypes } from "../Results";

export default function ProjetosPublicosCard({
  state: { isChecked },
  setState,
}: ProjPubCardProps) {
  const cardStyles = useCardStyles();

  return (
    <Card>
      <Checkbox
        checked={isChecked}
        onChange={(_, data) => setState({ isChecked: !!data.checked })}
        label={<b>{ResultTypes.ondemand_public_projects_company.label}</b>}
      />
    </Card>
  );
}
