import CalculatorPage from "Pages/Calculator";
import Layout from "Pages/Layout";
import LoginPage from "Pages/Login";
import MainPage from "Pages/Main";
import NotFoundPage from "Pages/NotFound";
import ReportsPage from "Pages/Reports";
import SearchPage from "Pages/Search";
import React from "react";
import { Route } from "react-router-dom";
const searchIcon = require("../svg/search_icon.svg").default;
const homeIcon = require("../svg/icons8-home.svg").default;
const reportsIcon = require("../svg/reports.svg").default;
const calculator_icon = require("../svg/calculator_icon.svg").default;

export type AppRoute = {
  path: string;
  component: React.JSX.Element;
  icon?: string;
  label?: string;
  index?: boolean;
  id: string;
};

export const appRoutes: AppRoute[] = [
  {
    id: "main",
    path: "/main",
    label: "Inicio",
    icon: homeIcon,
    component: <MainPage key="mainPage" />,
  },
  {
    id: "search",
    path: "/search",
    label: "Pesquisa",
    icon: searchIcon,
    component: <SearchPage key="searchPage" />,
  },
  {
    id: "reports",
    path: "/reports",
    label: "Relatorios",
    icon: reportsIcon,
    component: <ReportsPage key="reportsPage" />,
  },
  {
    id: "calculator",
    path: "/calculator",
    icon: calculator_icon,
    label: "Calculadora",
    component: <CalculatorPage key="calculatorPage" />,
  },
  {
    id: "catchall",
    path: "*",
    component: <NotFoundPage />,
  },
];

export const useRoutes = () => {
  return appRoutes.map((route) => (
    <Route
      index={route.index}
      key={`route${route.component.key}`}
      path={route.path}
      element={route.component}
    />
  ));
};
