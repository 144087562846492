import React from "react";
import {
  Card,
  Checkbox,
  Field,
  Radio,
  RadioGroup,
} from "@fluentui/react-components";
import { ProcessosJudiciaisCardProps } from "./ProcessosJudiciaisCard.types";
import { useCardStyles } from "../index.styles";
import { ResultTypes } from "../Results";

export default function ProcessosJudiciaisCard({
  options,
  state: { civel, trabalhista, tributario, searchInterval },
  setState,
}: ProcessosJudiciaisCardProps) {
  const cardStyles = useCardStyles();

  const isChecked =
    (civel || tributario || trabalhista) && searchInterval > 0
      ? true
      : searchInterval > 0 || civel || tributario || trabalhista
        ? "mixed"
        : false;

  return (
    <Card>
      <Checkbox
        checked={isChecked}
        onChange={(_, data) => {
          setState((prev) => ({
            trabalhista: !!data.checked,
            tributario: !!data.checked,
            civel: !!data.checked,
            searchInterval: 0,
          }));
        }}
        label={<b>{ResultTypes.processes.label}</b>}
      />
      <div className={cardStyles.sideBySideCb}>
        <Checkbox
          checked={trabalhista}
          onChange={(_, data) =>
            setState((prev) => ({ ...prev, trabalhista: !!data.checked }))
          }
          label="Trabalhista"
        />
        <Checkbox
          onChange={(_, data) =>
            setState((prev) => ({ ...prev, tributario: !!data.checked }))
          }
          checked={tributario}
          label="Tributario"
        />
        <Checkbox
          onChange={(_, data) =>
            setState((prev) => ({ ...prev, civel: !!data.checked }))
          }
          checked={civel}
          label="Civel"
        />
      </div>
      <Field label="Periodo">
        <RadioGroup
          required
          layout="horizontal"
          onChange={(_, data) =>
            setState((prev) => ({
              ...prev,
              searchInterval: Number(data.value),
            }))
          }
          value={searchInterval.toString()}
          className={cardStyles.sideBySideRadio}
        >
          {options.map((option) => (
            <Radio
              key={option.Value}
              value={option.Value.toString()}
              label={option.Label}
            />
          ))}
        </RadioGroup>
      </Field>
    </Card>
  );
}
