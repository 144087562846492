import React, { useEffect } from "react";
import { useCardStyles, useMainPageStyles } from "./index.styles";
import {
  Button,
  Card,
  Field,
  Input,
  Spinner,
  Title3,
} from "@fluentui/react-components";
import { FileSearch2 as SearchIcon } from "lucide-react";
import { LeiDoBemCardProps, LeiDoBemCardState } from "./LeiDoBem";
import { ProjPubCardProps, ProjPubCardState } from "./ProjetosPublicos";
import { ComprotCardProps, ComprotCardState } from "./Comprot";
import {
  ProcessosJudiciaisCardProps,
  ProcessosJudiciaisCardState,
} from "./ProcessosJudiciais";
import ComprotCard from "./Comprot/ComprotCard";
import ProjetosPublicosCard from "./ProjetosPublicos/ProjetosPublicosCard";
import LeiDoBemCard from "./LeiDoBem/LeiDoBemCard";
import {
  toggleSet,
  ComprotIntervalOptions,
  ProcJudIntervalOptions,
  formatAsCnpj,
  SearchType,
  ApiResponseType,
} from "Utils";
import ProcessosJudiciaisCard from "./ProcessosJudiciais/ProcessosJudiciaisCard";
import { useSearchProjetosPublicos } from "hooks/ProjetosPublicos/useSearchProjetosPublicos";
import { useSearchComprot } from "hooks/Comprot/useSearchComprot";
import ResultsCard from "./Results/ResultsCard";
import { SEARCH_STORAGE_KEY, addToStorageValue } from "hooks/useLocalStorage";
import PerDecomp from "./PerDecomp/PerDecomp";
import { PerDecompCardProps, PerDecompState } from "./PerDecomp";
import { useSearchPerDecomp } from "hooks/PerDecomp/useSearchPerDecomp";
import { has, uniqueId } from "lodash";
import AppCardMessageBar from "Components/AppMessageBar";
import { useProcessosJudiciais } from "hooks/ProcessosJudiciais/useProcessosJudiciais";
import DebitosFederaisCard from "./DebitosFederais/DebitosFederaisCard";
import {
  DebitosFederaisCardProps,
  DebitosFederaisState,
} from "./DebitosFederais";
import { useSearchDebitosFederais } from "hooks/DebitosFederais/useSearchDebitosFederais";
import { useSearchState } from "./hooks/useSearchHooks";
import { useAmpli } from "hooks/useAmpli";

export default function SearchPage() {
  const useStyles = useMainPageStyles();
  const cardStyles = useCardStyles();
  const { tracker } = useAmpli();
  const [searchState, setSearchState] = useSearchState();
  const cnpjFieldMessage =
    searchState.searchParams.doc == ""
      ? ""
      : searchState.searchParams.isCnpjValid
        ? null
        : "Digite um numero CNPJ valido.";

  const leiDoBemCardProps: LeiDoBemCardProps = {
    state: searchState.leiDoBem,
    setState: (state: LeiDoBemCardState) => {
      setSearchState({
        ...searchState,
        leiDoBem: state,
        searchType: toggleSet<SearchType>(
          searchState.searchType,
          SearchType.LeiDoBem,
          state.isChecked,
        ),
      });
    },
  };

  const projPubCardProps: ProjPubCardProps = {
    state: searchState.projetosPublicos,
    setState: (state: ProjPubCardState) => {
      setSearchState({
        ...searchState,
        projetosPublicos: state,
        searchType: toggleSet<SearchType>(
          searchState.searchType,
          SearchType.ProjetosPublicos,
          state.isChecked,
        ),
      });
    },
  };

  const perDecomp: PerDecompCardProps = {
    state: searchState.perDecomp,
    isDisabled: false,
    options: ComprotIntervalOptions,
    setState: (state: PerDecompState) => {
      setSearchState({
        ...searchState,
        perDecomp: state,
        searchType: toggleSet<SearchType>(
          searchState.searchType,
          SearchType.PerDecomp,
          state.isChecked,
        ),
      });
    },
  };

  const debitosFederaisCardProps: DebitosFederaisCardProps = {
    state: searchState.debitosFederais,
    setState: (state: DebitosFederaisState) => {
      setSearchState({
        ...searchState,
        debitosFederais: state,
        searchType: toggleSet<SearchType>(
          searchState.searchType,
          SearchType.DebitosFederais,
          state.isChecked,
        ),
      });
    },
  };

  const comprotCardProps: ComprotCardProps = {
    isDisabled: false,
    options: ComprotIntervalOptions,
    state: searchState.comprot,
    setState: (state: ComprotCardState) => {
      setSearchState({
        ...searchState,
        comprot: state,
        searchType: toggleSet<SearchType>(
          searchState.searchType,
          SearchType.Comprot,
          state.isChecked,
        ),
      });
    },
  };

  const processosJudiciaisCardProps: ProcessosJudiciaisCardProps = {
    options: ProcJudIntervalOptions,
    state: searchState.processosJudiciais,
    setState: (
      state: (
        state: ProcessosJudiciaisCardState,
      ) => ProcessosJudiciaisCardState,
    ) => {
      const canAddSearch =
        (state(searchState.processosJudiciais).civel ||
          state(searchState.processosJudiciais).trabalhista ||
          state(searchState.processosJudiciais).tributario) &&
        state(searchState.processosJudiciais).searchInterval > 0;
      setSearchState({
        ...searchState,
        processosJudiciais: state(searchState.processosJudiciais),
        searchType: toggleSet<SearchType>(
          searchState.searchType,
          SearchType.ProcessosJudiciais,
          canAddSearch,
        ),
      });
    },
  };

  const processosJudiciaisSearch = useProcessosJudiciais((data) => {
    addToStorageValue(SEARCH_STORAGE_KEY, {
      searchParams: searchState.searchParams,
      ...data.data,
    });
  });

  const projetosPublicosSearch = useSearchProjetosPublicos((data) => {
    addToStorageValue(SEARCH_STORAGE_KEY, {
      searchParams: searchState.searchParams,
      ...data.data,
    });
  });

  const perDecompSearch = useSearchPerDecomp((data) => {
    addToStorageValue(SEARCH_STORAGE_KEY, {
      searchParams: searchState.searchParams,
      QueryId: uniqueId(),
      Status: { perdecomp: ApiResponseType.PerDecomp },
      ElapsedMilliseconds: 0,
      Result: [
        {
          MatchKeys: null,
          PerDecomp: data.data,
        },
      ],
    });
  });

  const comprotSearch = useSearchComprot((data) => {
    addToStorageValue(SEARCH_STORAGE_KEY, {
      searchParams: searchState.searchParams,
      ...data.data,
    });
  });

  const debitosSearch = useSearchDebitosFederais((data) => {
    addToStorageValue(SEARCH_STORAGE_KEY, {
      searchParams: searchState.searchParams,
      ...data.data,
    });
  });

  const pesquisar = () => {
    tracker.search({
      document: searchState.searchParams.doc,
      types: Array.from(searchState.searchType),
    });
    searchState.searchType.forEach((type) => {
      if (type == SearchType.ProcessosJudiciais)
        processosJudiciaisSearch.mutate(searchState);

      if (type == SearchType.PerDecomp) perDecompSearch.mutate(searchState);

      if (type == SearchType.Comprot) comprotSearch.mutate(searchState);

      if (type == SearchType.DebitosFederais) debitosSearch.mutate(searchState);

      if (type == SearchType.ProjetosPublicos)
        projetosPublicosSearch.mutate(searchState);
    });
  };

  const getError = () => {
    let error: Error = null;
    let hasError = false;

    if (debitosSearch.isError) {
      error = debitosSearch.error;
      hasError = debitosSearch.isError;
    }

    if (processosJudiciaisSearch.isError) {
      error = processosJudiciaisSearch.error;
      hasError = processosJudiciaisSearch.isError;
    }

    if (projetosPublicosSearch.isError) {
      error = projetosPublicosSearch.error;
      hasError = projetosPublicosSearch.isError;
    }

    if (perDecompSearch.isError) {
      error = perDecompSearch.error;
      hasError = perDecompSearch.isError;
    }

    return { errorMessage: error?.message, hasError };
  };

  const isSearchLoading = () => {
    return (
      projetosPublicosSearch.isPending ||
      perDecompSearch.isPending ||
      processosJudiciaisSearch.isPending ||
      comprotSearch.isPending ||
      debitosSearch.isPending ||
      !!!searchState.searchParams.isCnpjValid
    );
  };

  return (
    <>
      <div className={useStyles.container}>
        <Card className={cardStyles.card}>
          <AppCardMessageBar
            isError={getError().hasError}
            key={uniqueId()}
            message={getError().errorMessage}
          />
          <div>
            <SearchIcon style={{ marginRight: "11px", paddingTop: "2px" }} />
            <Title3 className={cardStyles.title3}>Configurar pesquisa</Title3>
          </div>
          <ComprotCard {...comprotCardProps} />
          <PerDecomp {...perDecomp} />
          <ProcessosJudiciaisCard {...processosJudiciaisCardProps} />
          <ProjetosPublicosCard {...projPubCardProps} />
          <LeiDoBemCard {...leiDoBemCardProps} />
          <DebitosFederaisCard {...debitosFederaisCardProps} />
          <div className={cardStyles.mainCardInputsContainer}>
            <Field
              label="Numero CNPJ:"
              validationState={
                searchState.searchParams.doc == ""
                  ? "none"
                  : searchState.searchParams.isCnpjValid == true
                    ? "none"
                    : "error"
              }
              validationMessage={cnpjFieldMessage}
            >
              <Input
                value={searchState.searchParams.doc}
                className={cardStyles.mainCardInputs}
                maxLength={18}
                onChange={(_, data) => {
                  const [cnpj, isValid] = formatAsCnpj(data.value);
                  setSearchState((prev) => ({
                    ...prev,
                    searchParams: {
                      doc: cnpj,
                      isCnpjValid: isValid,
                    },
                  }));
                }}
              />
            </Field>
            <Field label="Nome Empresa:">
              <Input
                onChange={(_, data) =>
                  setSearchState((prev) => ({ ...prev, doc: data.value }))
                }
                className={cardStyles.mainCardInputs}
              />
            </Field>
          </div>
          <Button
            disabled={!!isSearchLoading() || searchState.searchType.size == 0}
            onClick={() => pesquisar()}
            appearance="primary"
          >
            Pesquisar ({searchState.searchType.size})
          </Button>
          {comprotSearch.isPending && <Spinner />}
        </Card>
        <ResultsCard />
      </div>
    </>
  );
}
