import { Variant, Variants } from "@amplitude/experiment-js-client";

export enum FeatureFlag {
  NewLinks = "newlinks",
}

export const FeatureFlags: Variants = {
  [FeatureFlag.NewLinks]: {
    key: "default",
    value: "default",
    expKey: "newlinks",
    payload: {
      links: ["main", "search", "catchall"],
    },
  },
};
