import React from "react";
import {
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Persona,
  Link,
} from "@fluentui/react-components";
import type { PopoverProps } from "@fluentui/react-components";
import { LoginSuccessResponse } from "hooks/Login/useLogin";

export type AvatarPopoverProps = PopoverProps & {
  loginInfo: LoginSuccessResponse;
  logoutHandler: any;
};

export const AvatarPopover = ({
  children,
  loginInfo,
  logoutHandler,
}: AvatarPopoverProps) => (
  <Popover>
    <PopoverTrigger disableButtonEnhancement>
      <div>{children}</div>
    </PopoverTrigger>
    <PopoverSurface tabIndex={-1}>
      <Persona
        textAlignment="start"
        name={loginInfo.login}
        // presence={{ status: "available" }}
        secondaryText="Expira em: 10/10/1000"
        tertiaryText={
          loginInfo.login == "lucaszerma" ? "Administrador" : "Advogado"
        }
        quaternaryText={
          <Link appearance="default" onClick={logoutHandler}>
            Sair
          </Link>
        }
      />
    </PopoverSurface>
  </Popover>
);
