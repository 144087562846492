import React from "react";
import { Archive as ArchiveIcon } from "lucide-react";
import {
  DataGrid,
  createTableColumn,
  DataGridHeader,
  DataGridRow,
  DataGridHeaderCell,
  DataGridBody,
  DataGridCell,
  Text,
  TableColumnDefinition,
  Card,
  Title3,
} from "@fluentui/react-components";
import {
  ResultCardItem,
  ResultTypes,
  ResultsCardProps,
} from "./ResultsCard.types";
import moment from "moment";
import { useCardStyles } from "../index.styles";
import {
  ResultItem,
  SEARCH_STORAGE_KEY,
  getStorageValue,
} from "hooks/useLocalStorage";
import { useMapResultCardItem } from "./useMapResultCardItem";
import { match } from "ts-pattern";
import { ApiResponseType } from "Utils";
import { CreateFileRender } from "./renders/CreateFileRender";
import { RawFileRender } from "./renders/RawFileRender";

const columns: TableColumnDefinition<ResultCardItem>[] = [
  createTableColumn<ResultCardItem>({
    columnId: "doc",
    compare: (a, b) => {
      return a.doc.localeCompare(b.doc);
    },
    renderHeaderCell: () => {
      return "CNPJ";
    },
    renderCell: (item) => {
      return item.doc;
    },
  }),
  createTableColumn<ResultCardItem>({
    columnId: "queryDate",
    compare: (a, b) => {
      return a.queryDate < b.queryDate ? 0 : 1;
    },
    renderHeaderCell: () => {
      return "Data da pesquisa";
    },
    renderCell: (item) => {
      return moment(item.queryDate).format("DD/MM/YYYY");
    },
  }),
  createTableColumn<ResultCardItem>({
    columnId: "file",
    compare: (a, b) => {
      return a.file.label.localeCompare(b.file.label);
    },
    renderHeaderCell: () => {
      return "Arquivo";
    },
    renderCell: (item: ResultCardItem) => {
      return match(item)
        .with({ apiType: ApiResponseType.Comprot }, () => {
          return <RawFileRender item={item} key={item.queryId} />;
        })
        .with({ apiType: ApiResponseType.PerDecomp }, () => {
          return <CreateFileRender item={item} key={item.queryId} />;
        })
        .with({ apiType: ApiResponseType.ProcessosJudiciais }, () => {
          return <CreateFileRender item={item} key={item.queryId} />;
        })
        .with({ apiType: ApiResponseType.DebitosFederais }, () => {
          return <CreateFileRender item={item} key={item.queryId} />;
        })
        .with({ apiType: ApiResponseType.ProjetosPublicos }, () => {
          return <CreateFileRender item={item} key={item.queryId} />;
        })
        .with({ apiType: ApiResponseType.LeiDoBem }, () => {
          return item.doc;
        })
        .exhaustive();
    },
  }),
  createTableColumn<ResultCardItem>({
    columnId: "dataset",
    compare: (a, b) => {
      return a.dataset.label.localeCompare(b.dataset.label);
    },
    renderHeaderCell: () => {
      return "API";
    },
    renderCell: (item) => ResultTypes[item.apiType].label,
  }),
];

const getRowId = (item: ResultItem) => {
  return item.QueryId;
};

export default function ResultsCard({}: ResultsCardProps) {
  const data = getStorageValue(SEARCH_STORAGE_KEY);
  const results: ResultCardItem[] = Array.from(data).map(useMapResultCardItem);
  const cardStyles = useCardStyles();
  return (
    <Card style={{ width: "100%" }} className={cardStyles.card}>
      <div>
        <ArchiveIcon style={{ marginRight: "11px", paddingTop: "2px" }} />
        <Title3 className={cardStyles.title3}>Pesquisas realizadas</Title3>
      </div>
      <DataGrid
        arial-label="Default table"
        items={results}
        columns={columns}
        sortable
        getRowId={getRowId}
      >
        <DataGridHeader>
          <DataGridRow>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell>
                <Text weight="bold">{renderHeaderCell()}</Text>
              </DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<ResultCardItem>>
          {({ item, rowId }) => (
            <DataGridRow<ResultCardItem> key={rowId}>
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </Card>
  );
}
