import React, { useEffect } from "react";
import { useLoginPageStyles } from "./index.styles";
import { Outlet, useNavigate } from "react-router-dom";
import { useForm } from "@tanstack/react-form";
import {
  Button,
  Field,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
} from "@fluentui/react-components";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";
import { fieldErrors, isFormFieldValid } from "Utils/FormValidators";
import { useLoginMutation } from "../../hooks/Login/useLogin";
import { useAmpli } from "hooks/useAmpli";

export default function LoginPage() {
  const useStyles = useLoginPageStyles();
  const navigate = useNavigate();
  const { tracker } = useAmpli();

  const {
    mutate: loginMutation,
    data: loginResult,
    isError,
    isSuccess,
    error,
  } = useLoginMutation();

  const form = useForm({
    defaultValues: {
      login: "",
      password: "",
    },
    onSubmit: async ({ value }) => {
      loginMutation({
        login: value.login,
        password: value.password,
      });
    },
    validatorAdapter: zodValidator,
  });

  useEffect(() => {
    tracker.login({
      email: form.getFieldValue("login"),
      errorMessage: isError ? error.message : null,
    });
    if (isSuccess) {
      navigate("/main");
    }
  }, [isSuccess, isError]);

  return (
    <div className={useStyles.container}>
      {isError && (
        <MessageBar intent="warning">
          <MessageBarBody>
            <MessageBarTitle>
              Um erro ocorreu ao tentar realizar o login
            </MessageBarTitle>
            {error.message}
          </MessageBarBody>
        </MessageBar>
      )}
      <div className={useStyles.formContainer}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <form.Field
            name="login"
            validators={{
              onChange: z
                .string()
                .min(5, { message: "Precisa ter mais que 5 caracteres" }),
            }}
            children={(field) => {
              // Avoid hasty abstractions. Render props are great!
              return (
                <>
                  <Field
                    label="Usuario"
                    validationState={isFormFieldValid({ field })}
                    validationMessage={fieldErrors({ field })}
                  >
                    <input
                      id={field.name}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                    />
                  </Field>
                </>
              );
            }}
          />

          <form.Field
            name="password"
            validators={{
              onChange: z
                .string()
                .min(5, { message: "Precisa ter mais que 5 caracteres" }),
            }}
            children={(field) => {
              return (
                <>
                  <Field
                    label="Senha"
                    validationState={isFormFieldValid({ field })}
                    validationMessage={fieldErrors({ field })}
                  >
                    <input
                      type="password"
                      id={field.name}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                    />
                  </Field>
                </>
              );
            }}
          />
          <div className={useStyles.formButtonsContainer}>
            <form.Subscribe
              selector={(state) => [state.canSubmit, state.isSubmitting]}
              children={([canSubmit, isSubmitting]) => (
                <>
                  <Button
                    type="submit"
                    className={useStyles.formButtons}
                    appearance="primary"
                    disabled={!canSubmit}
                  >
                    {isSubmitting ? "..." : "Entrar"}
                  </Button>
                  <Button
                    className={useStyles.formButtons}
                    appearance="secondary"
                    disabled={isSubmitting}
                  >
                    Limpar
                  </Button>
                </>
              )}
            />
          </div>
        </form>
      </div>
      <Outlet />
    </div>
  );
}
